
<template>
  <div v-show="test" style="margin: -15px -15px;position: relative">
    <a-spin :spinning="spinning">
    <iframe
        id="testIframe"
        style="width: 100%;min-height: 2680px;"
        frameborder="0"
        scrolling="no"
        :src="'https://questionnaire.yunicu.com/#/admin/b?id='+router_id + '&user_id=' + user_id">
    </iframe>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div style="width: 82%;display: flex;justify-content: right;margin-top: 10px">
        <a-button
            @click="returnBtn">返回上一层</a-button>
        <a-button
            v-show="!passValue"
            style="margin-left: 10px"
            @click="overruleBtn">驳回</a-button>
        <a-button
            v-show="!passValue"
            type="primary"
            style="margin-left: 10px"
            @click="passBtn">通过</a-button>
      </div>
    </div>
<!-----------------------------  对话框  --------------------------->
        <a-modal
            width="700px"
            v-model="visible"
            title="审批">
          <template slot="footer">
            <a-button @click="quxiaoModel">
              取消
            </a-button>
            <a-button type="primary"  @click="overruleModel">
              确定
            </a-button>
          </template>
          <div style="width: 100%;display: flex">
            <div style="width:20%;text-align: right;font-weight: bold;color: black">驳回原因 :</div>
            <a-checkbox-group
                v-model="checkboxVal"
                @change="checkbox_group_onChange"
                style="margin-left: 10px;width: 600px;">
              <a-row>
                <a-col style="margin-bottom: 8px" :span="24" v-for="(overRule,index) in overRules">
                  <a-checkbox
                      :value="overRule">
                    {{overRule}}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
          <a-textarea
              style="width:500px;margin-left: 120px;margin-top: 10px;"
              v-model="overruleValue"
              placeholder="请输入其他驳回原因"
              :auto-size="{ minRows: 6, maxRows: 10 }"
          />
        </a-modal>
    </a-spin>
  </div>
</template>
<script>
import {getCaseMessage, getCaseMessage_course, putCaseGo} from "../../../service/case_api";

export default {
  data(){
    return{
      checkboxVal:[],
      overRules:[],
      // passBtnVal:true,
      spinning:false,
      router_id:'',
      user_id:'',
      test:true,
      visible:false,
      overruleValue:'',
      state:'',

    }
  },
  created() {
    console.log(this.$route.query.templeteId)
  },
  computed:{
    passValue(){
      return this.$store.state.passBtn
    },
  },
  mounted() {
    this.getRouter_id()
    this.getCase()
    this.getCaseCourse()
  },
  methods:{
  //  获取url地址中的id
    getRouter_id(){
      const id = this.$route.query.id
      this.router_id = id
      this.user_id = this.$route.query.user_id
    },
    //获取驳回原因
    async getCaseCourse() {
      let id = this.$route.query.templeteId
      const response = await getCaseMessage_course(id)
      if(response.code === 0){
        this.overRules = response.data.remark
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取病例详情中的审核状态
    async getCase() {
      //  获取路径中的id
      const id = this.router_id
      const reponce = await getCaseMessage(id)
      if (reponce.code === 0) {
        //获取接口中的状态
        // 审核状态：PENDING：待审核 ;PASSED：已通过  ;DENIED：已拒绝
        this.state = reponce.data.audit_status
        this.overruleValue = reponce.data.reject_reason
      }
    },
    //底部按钮通过按钮
    async passBtn() {
      this.spinning = true
      const data = {
        "audit_status":"PASSED",
        // "reject_reason":reject_reason
      }
      const reponse = await putCaseGo(this.router_id,data)
      if (reponse.code === 0) {
            this.$message.success('已通过！'+reponse.message)
          }else {
            this.$message.error("失败，请联系管理员！！" + reponse.message)
          }
      this.spinning = false
      },
      //返回上一层
      returnBtn() {
        window.history.back()
      },
      //驳回
      overruleBtn() {
        this.visible = true;
      },
      quxiaoModel() {
        this.visible = false;
      },
    checkbox_group_onChange(){
      console.log(this.checkboxVal.join(';'))
      this.overruleValue = this.checkboxVal.join(';')
    },
      //驳回确定按钮
      async overruleModel() {
        this.spinning = true
        const data = {
          "audit_status": "DENIED",
          "reject_reason":this.overruleValue
        }
        const reponse = await putCaseGo(this.router_id, data)
        if (reponse.code === 0) {
          this.$message.success('已驳回！' + reponse.message)
        } else {
          this.$message.error("失败，请联系管理员！！" + reponse.message)
        }
        this.visible = false
        this.spinning = false
      }
    },
}
</script>
